import * as grid from '../styles/grid.module.scss';
import * as styles from '../styles/vippeextensionsvarianter.module.scss';
import ArticleLayout from "../components/articleLayout/ArticleLayout";
import Divider from "../components/divider/Divider";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import SeeAvailableButton from "../components/button/SeeAvailableButton";
import Seo from "../components/Seo";
import SubHeading from "../components/contentHeadings/SubHeading";
import TertiaryHeading from "../components/contentHeadings/TertiaryHeading";
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";

const VippeextensionsVarianter = props => {

    const k1 = <StaticImage
        src={"../images/vippeextensions/varianter/klassisk/klassisk1.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 1"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const k2 = <StaticImage
        src={"../images/vippeextensions/varianter/klassisk/klassisk2.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 2"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const k3 = <StaticImage
        src={"../images/vippeextensions/varianter/klassisk/klassisk3.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 3"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const k4 = <StaticImage
        src={"../images/vippeextensions/varianter/klassisk/klassisk4.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 4"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const k5 = <StaticImage
        src={"../images/vippeextensions/varianter/klassisk/klassisk5.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 5"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const k6 = <StaticImage
        src={"../images/vippeextensions/varianter/klassisk/klassisk6.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 6"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const v1 = <StaticImage
        src={"../images/vippeextensions/varianter/volum/1.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 1"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const v2 = <StaticImage
        src={"../images/vippeextensions/varianter/volum/2.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 2"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const v3 = <StaticImage
        src={"../images/vippeextensions/varianter/volum/3.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 3"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const v4 = <StaticImage
        src={"../images/vippeextensions/varianter/volum/4.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 4"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const v5 = <StaticImage
        src={"../images/vippeextensions/varianter/volum/5.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 5"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

    const v6 = <StaticImage
        src={"../images/vippeextensions/varianter/volum/6.jpg"}
        alt={"Klassiske Vippeextensions, eksempel 6"}
        width={450}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        outputPixelDensities={outputPixelDensities}
    />;

  //   const images = useStaticQuery(graphql`
  //       query {
  //         k1: file(relativePath: { eq: "vippeextensions/varianter/klassisk/klassisk1.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         k2: file(relativePath: { eq: "vippeextensions/varianter/klassisk/klassisk2.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         k3: file(relativePath: { eq: "vippeextensions/varianter/klassisk/klassisk3.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         k3: file(relativePath: { eq: "vippeextensions/varianter/klassisk/klassisk3.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         k4: file(relativePath: { eq: "vippeextensions/varianter/klassisk/klassisk4.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         k5: file(relativePath: { eq: "vippeextensions/varianter/klassisk/klassisk5.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         k6: file(relativePath: { eq: "vippeextensions/varianter/klassisk/klassisk6.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         v1: file(relativePath: { eq: "vippeextensions/varianter/volum/1.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         v2: file(relativePath: { eq: "vippeextensions/varianter/volum/2.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         v3: file(relativePath: { eq: "vippeextensions/varianter/volum/3.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         v4: file(relativePath: { eq: "vippeextensions/varianter/volum/4.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         v5: file(relativePath: { eq: "vippeextensions/varianter/volum/5.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         },
  //         v6: file(relativePath: { eq: "vippeextensions/varianter/volum/6.jpg" }) {
  //           childImageSharp {
  //             fluid(maxWidth: 450, quality: 80, sizes: "(min-width: 960px): 214px, calc(50vw - 40px)") {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         }
  //       }
  // `);

    return(
        <ArticleLayout>
            <Seo title={'Vippeextensions-varianter, klassisk og volum'} />
            <section>
                <PageTitle>Vippeextensions-varianter</PageTitle>
                <p>Det er to hovedkategorier vippeextensions: klassisk og volum. Om du ønsker en mellomting kan vi sette på en miks av begge varianter. I vårt bookingsystem kan du velge mellom naturlig volum og dramatisk volum. Forskjellen er hvor tett resultatet blir. I Dramatisk volum er det flere hår i en “vifte” enn det er i naturlig volum.</p>
                <div className={grid.container}>
                    <div className={grid.xs12Sm6P8}>
                        <TertiaryHeading>Klassisk</TertiaryHeading>
                        <ul>
                            <li>Fremhever dine naturlige vipper, og gir en mascara-look.</li>
                            <li>Kan bli alt fra helt naturlig til ganske markert, etter ditt ønske.</li>
                            <li>Se utfyllende beskrivelse <a href={'#klassisk'}>HER</a>.</li>
                        </ul>
                    </div>
                    <div className={grid.xs12Sm6P8}>
                        <TertiaryHeading>Volum</TertiaryHeading>
                        <ul>
                            <li>Gir fyldere look. </li>
                            <li>Kan bli alt fra ganske naturlig til superdramatisk, etter ditt ønske.</li>
                            <li>Kan settes på selv svakere naturlige vipper, og fylle ut dersom du har få naturlige vipper.</li>
                            <li>Se utfyllende beskrivelse <a href={'#volum'}>HER</a>.</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section>
                <SubHeading>Klassiske Vippeextensions</SubHeading>
                <p>Klassiske vipper går ut på at én falsk vipp blir festet til én naturlig vipp (Dette kalles også “én til én” eller 1D i vippeextensions-lingo). Med klassiske vipper får du en mascara-look, siden antallet vipper ikke øker, men dine naturlige vipper fremheves. Hvor stor effekten blir kan vi tilpasse etter dine ønsker. Det finnes mange forskjellige typer vippeextensions-hår som passer til forskjellige mennesker. Vi har alle varianter av krøller, lengder og tykkelser i salongen. Du kan også komme med et ønske om hvor naturlig eller markert du vil at effekten skal være, så prøver vi så godt vi kan å gi deg det du ønsker. </p>
                <p>Men det er en grense på hvor mye en naturlig vipp tåler. Lange, tykke naturlige vipper tåler større extensions, og man kan ha dem ganske dramatiske og tykke, mens naturlig svake vipper kanskje bare tåler de tynneste og letteste vippene. Hvor fyldige vippene blir, kommer altså helt an på dine egne naturlige vipper. Klassiske vipper passer bra for deg som har en normal mengde naturlige vipper, og som ikke vil ha dem for dramatiske.</p>
                <p>Vi i salongen kommer gjerne med en anbefaling hvor vi ser hva som vil passe best til deg. Vi pleier å bruke naturlig lengde med klassiske vipper, fordi de vil holde bedre, ikke være så tunge og de vil se finere ut etter 2-3 uker. Klassiske vipper spriker mer enn volum, så hvis man ønsker veldig lange vipper pleier vi å anbefale volum. Klassiske vipper er flott til deg som vil ha en naturlig look - som ser ut som man har naturlig vakre vipper.</p>
                <div className={grid.container}>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.k1.childImageSharp.fluid}*/}
                        {/*     alt={"Klassiske Vippeextensions, eksempel 1"}*/}
                        {/*/>*/}
                        { k1 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.k2.childImageSharp.fluid}*/}
                        {/*     alt={"Klassiske Vippeextensions, eksempel 2"}*/}
                        {/*/>*/}
                        { k2 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.k3.childImageSharp.fluid}*/}
                        {/*     alt={"Klassiske Vippeextensions, eksempel 3"}*/}
                        {/*/>*/}
                        { k3 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.k4.childImageSharp.fluid}*/}
                        {/*     alt={"Klassiske Vippeextensions, eksempel 4"}*/}
                        {/*/>*/}
                        { k4 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.k5.childImageSharp.fluid}*/}
                        {/*     alt={"Klassiske Vippeextensions, eksempel 5"}*/}
                        {/*/>*/}
                        { k5 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.k6.childImageSharp.fluid}*/}
                        {/*     alt={"Klassiske Vippeextensions, eksempel 6"}*/}
                        {/*/>*/}
                        { k6 }
                    </div>
                </div>
            </section>
            <Divider color={'pink'} />
            <section>
                <SubHeading>Volum-vippeextensions</SubHeading>
                <p>Volumvipper gir fylde. Volumvipper er ekstra tynne falske vipper (Ofte 0.07mm-0.03mm), men hvor man setter flere falske vipper på én naturlig vipp. Man kan få alt fra 2D (2 hår i en "vifte") til 16D (16 hår i en "vifte") på hver naturlige vipp. Desto flere hår vi fester i hver vipp, desto svartere og fyldigere blir det. Dersom du har normalt sterke vipper kan du velge fylden helt selv. Er dine naturlige vipper svake bør vi ikke gjøre viftene alt for store, men dette ser vi i salongen. Det er også mulig å få en blanding av klassisk og volum. Da kan vi sette et nytt sett med en blanding, eller ta påfyll med enten volum eller klassisk.</p>
                <p>Når du kommer innom salongen kan vi selvfølgelig hjelpe deg med å velge. Vi kan god erfaring med å se hva slags vippeextensions som vil passe best, ut ifra hva hva slags look du ønsker (dramatisk/naturlig), og ut i fra dine naturlige vipper. Du kan gjerne vise til et bilde for å gi oss en idé om hva slags look du ønsker, men husk at det ikke er noen garanti for at samme look er mulig, fordi dine naturlige vipper er helt unike. Med volum-extensions bruker vi litt lengre tid enn klassisk, siden vi håndlager hver vift tilpasset hver naturlige vipp. </p>
                <p>Det er også mulig med mix-vipper. Det er en blanding mellom klassisk og volum, eller veldig naturlig volum. Dette kan man få med nytt sett, eller hvis man har klassiske vipper og fyller på med volum.</p>
                <div className={grid.container}>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.v1.childImageSharp.fluid}*/}
                        {/*     alt={"Volum Vippeextensions, eksempel 1"}*/}
                        {/*/>*/}
                        { v1 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.v2.childImageSharp.fluid}*/}
                        {/*     alt={"Volum Vippeextensions, eksempel 2"}*/}
                        {/*/>*/}
                        { v2 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.v3.childImageSharp.fluid}*/}
                        {/*     alt={"Volum Vippeextensions, eksempel 3"}*/}
                        {/*/>*/}
                        { v3 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.v4.childImageSharp.fluid}*/}
                        {/*     alt={"Volum Vippeextensions, eksempel 4"}*/}
                        {/*/>*/}
                        { v4 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.v5.childImageSharp.fluid}*/}
                        {/*     alt={"Volum Vippeextensions, eksempel 5"}*/}
                        {/*/>*/}
                        { v5 }
                    </div>
                    <div className={`${grid.xs6Md4P8} ${styles.image}`}>
                        {/*<Img fluid={images.v6.childImageSharp.fluid}*/}
                        {/*     alt={"Volum Vippeextensions, eksempel 6, lilla fantasy-vipper"}*/}
                        {/*/>*/}
                        { v6 }
                    </div>
                </div>
            </section>
            <SeeAvailableButton>Se ledige timer</SeeAvailableButton>
        </ArticleLayout>
    )
};

export default VippeextensionsVarianter;